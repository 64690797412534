import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import FormLabel from "@material-ui/core/FormLabel";
import Loading from "../../components/Loading";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import PaymentSystem from "./payCards";

const Title = ({ classes, addressName, title }) => (
	<FormLabel className={classes.formLegend} component="legend">
		<Typography className={classes.titleText} variant="h3">
			{title}
		</Typography>
	</FormLabel>
);

const PayCard = (props) => {
	const classes = useStyles();
	const isMin600 = useMediaQuery("(max-width: 599px)");
	const isMax600 = useMediaQuery("(min-width: 600px)");
	let addressName;

	if (
		!props.shops
		//  !props.shops.address
	) {
		return null;
	}

	// addressName = props.shops.address;

	return (
		<Grid container>
			<Grid item xs={12}>
				{isMin600 && (
					<Typography className={classes.titleMobile} variant="h3">
						Системы оплаты
					</Typography>
				)}
				<Paper className={classes.paper}>
					{isMax600 && (
						<Title
							classes={classes}
							title="Системы оплаты"
							addressName={addressName}
						/>
					)}
					{props.shops ? (
						<FormGroup>
							<FormControlLabel
								className={classes.FormControlLabel}
								control={
									<Switch
										className={classes.checked}
										checked={props.statusPayment}
										onChange={() => props.updateStatusApp()}
										value={props.statusPayment}
										color="primary"
										inputProps={{ "aria-label": "primary checkbox" }}
									/>
								}
								label="Система оплаты картой"
							/>
						</FormGroup>
					) : (
						<Loading size={22} color={"#326dff"} />
					)}
					{/* <PaymentSystem
						{...props}
						checked={props.shops.checkoutScreen.isCardAvailable}
					/> */}
				</Paper>
			</Grid>
		</Grid>
	);
};
const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(2),
		paddingTop: 24,
		paddingBottom: 16,
	},
	titleText: {
		fontFamily: "Roboto",
		fontSize: 22,
		lineHeight: 1,
		fontWeight: "bold",
		color: "black",
		letterSpacing: "0.003em",
	},
	titleMobile: {
		marginTop: 12,
		marginBottom: 8,
		fontFamily: "Roboto",
		fontSize: 22,
		lineHeight: 1,
		fontWeight: "bold",
		color: "black",
		letterSpacing: "0.003em",
	},
	legend: {
		fontSize: 16,
		fontWeight: 500,
		color: "black",
	},
	arrowIcon: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: 24,
		height: 24,
		margin: "0 5px",
	},
	formControl: {
		width: "100%",
		marginTop: 24,
	},
	formLegend: {
		display: "flex",
		marginBottom: 12,
	},
	imgContainer: {
		width: "100%",
		height: "auto",
		display: "flex",
		justifyContent: "center",
	},
	disableApp: {
		width: "100%",
		maxWidth: 300,
		height: "auto",
		margin: "auto",
	},
	title: {
		marginBottom: 10,
		fontSize: 18,
	},
	checked: {
		[theme.breakpoints.down("600")]: {
			order: 1,
		},
		"& .Mui-checked .MuiSwitch-thumb": {
			color: "#326DFF",
		},
		"& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track": {
			backgroundColor: "#326DFF",
		},
	},
	FormControlLabel: {
		display: "flex",
		maxWidth: 300,
		fontSize: 14,
		lineHeight: "16px",
		color: "#476282",
		letterSpacing: "0.003em",
		[theme.breakpoints.down("600")]: {
			paddingLeft: 10,
		},
	},
}));

export default PayCard;
