import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Switch from "../../components/Switch";
import LabelModal from "./LabelModal"; // Импортируем новый компонент

const Labels = ({
	labels,
	changeChecker,
	createLabel,
	deleteLabel,
	updateLabel,
	uploadImage,
	handleDeleteImg,
}) => {
	const classes = useStyles();
	const [openModal, setOpenModal] = useState(false);
	const [selectedLabel, setSelectedLabel] = useState(null);
	const [deleteId, setDeleteId] = useState();

	const handleOpenModal = (label = null, index = null) => {
		if (label) {
			setDeleteId(label.key);
		}
		setSelectedLabel(label ? { ...label, index } : null);
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setSelectedLabel(null);
		setOpenModal(false);
	};

	const handleSaveLabel = (labelData) => {
		if (selectedLabel) {
			updateLabel(selectedLabel.key, {
				tagName: "",
				isActive: labelData.isActive,
				position: labelData.position,
				image: labelData.image,
				productsIds:
					labelData.productsIds.length > 0 ? labelData.productsIds : [""],
			});
		} else {
			// Создаем новый лейбл
			createLabel(labelData);
		}
		handleCloseModal();
	};

	return (
		<div className={classes.labels}>
			<div className={classes.labelsHeaderWrap}>
				<Typography className={classes.typography} variant="h5">
					Лейблы
				</Typography>
				<Button
					className={classes.button}
					variant="contained"
					color="primary"
					onClick={() => handleOpenModal()}
				>
					Создать лейбл
				</Button>
			</div>
			<div className={classes.labelsContainer}>
				{labels?.length > 0 ? (
					Object.keys(labels).map((key) => {
						return (
							<div className={classes.labelItem} key={key}>
								<img
									src={labels[key].image}
									alt={labels[key].name || key}
									className={classes.labelImage}
								/>
								<div className={classes.labelActions}>
									<EditIcon
										className={classes.editIcon}
										onClick={() => handleOpenModal({ key, ...labels[key] })}
									/>
									<DeleteOutlineIcon
										className={classes.trashIcon}
										onClick={() => deleteLabel(key)}
									/>
									<Switch
										id={key}
										active={labels[key].isActive}
										changeChecker={(value) => changeChecker(value, key)}
									/>
								</div>
							</div>
						);
					})
				) : (
					<Typography className={classes.noLabelsText}>
						Нет доступных лейблов
					</Typography>
				)}
			</div>

			<LabelModal
				id={deleteId}
				open={openModal}
				onClose={handleCloseModal}
				onSave={handleSaveLabel}
				initialData={selectedLabel}
				uploadImage={uploadImage}
				handleDeleteImg={handleDeleteImg}
			/>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	labels: {
		minHeight: 148,
		marginTop: 30,
		padding: 16,
		border: "1px solid #DADCE0",
		boxSizing: "border-box",
		borderRadius: 5,
		[theme.breakpoints.down("600")]: {
			backgroundColor: "white",
		},
	},
	labelsHeaderWrap: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	button: {
		width: 140,
		height: 36,
		fontSize: "12px",
		uploadButton: {
			backgroundColor: theme.palette.primary.main,
			color: "white",
			"&:hover": {
				backgroundColor: theme.palette.primary.dark,
			},
		},
	},
	labelsContainer: {
		marginTop: 20,
	},
	labelItem: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		padding: "10px 0",
		borderBottom: "1px solid #DADCE0",

		"& img": {
			maxWidth: 100, // Без кавычек для числового значения
		},
	},
	labelText: {
		fontWeight: 500,
		fontSize: 16,
		lineHeight: "19px",
	},
	labelActions: {
		display: "flex",
		alignItems: "center",
	},
	editIcon: {
		width: 22,
		height: 22,
		marginRight: 15,
		color: "#476282",
		"&:hover": {
			color: "#326dff",
			cursor: "pointer",
		},
	},
	trashIcon: {
		width: 22,
		height: 22,
		color: "#476282",
		"&:hover": {
			color: "#326dff",
			cursor: "pointer",
		},
	},
	noLabelsText: {
		fontWeight: 500,
		fontSize: 16,
		lineHeight: "19px",
		color: "#476282",
		marginTop: 20,
		textAlign: "center",
	},
	modal: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 400,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(4),
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing(2),
	},
	modalButton: {
		marginTop: theme.spacing(2),
	},
}));

export default Labels;
