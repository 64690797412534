import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import ListCategory from "./listCategories";

const Categories = (props) => {
	const classes = useStyles();
	const path = props.match.url;

	const [categoriesOrder, setCategoriesOrder] = useState(null);
	const [categoriesList, setCategoriesList] = useState();
	const [isScrollRestored, setIsScrollRestored] = useState(false);

	const saveScrollPosition = () => {
		const currentPosition = window.scrollY;
		sessionStorage.setItem("scrollPositionCategories", currentPosition);
	};

	const restoreScrollPosition = () => {
		const savedPosition = sessionStorage.getItem("scrollPositionCategories");
		if (savedPosition && !isScrollRestored && categoriesList) {
			requestAnimationFrame(() => {
				window.scrollTo(0, parseInt(savedPosition, 10));
				setIsScrollRestored(true);
			});
		}
	};

	const getCategoriesList = () => {
		const sortedCategories = [];

		for (const categoryId of categoriesOrder) {
			const category = props.categories.find(
				(cat) => cat.categoryId === categoryId
			);

			if (category) {
				sortedCategories.push({
					id: category.categoryId,
					name: category.name.uk,
					...category,
				});
			}
		}

		const resultCategories =
			sortedCategories.length > 0 ? sortedCategories : props.categories;

		setCategoriesList(resultCategories);
		// if (sortedCategories.length > 0) setCategoriesList(resultCategories);
	};

	const getCategoriesOrderData = () => {
		const keyBrand = JSON.parse(localStorage.getItem("chosenBrand"));
		const keyCity = JSON.parse(localStorage.getItem("chosenCity"));
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		props.firebase.bd
			.ref(
				`${
					isDebug ? "debug" : ""
				}/settings/city/${keyCity}/brands/${keyBrand}/category/categoriesOrder`
			)
			.once("value", (snapshot) => {
				const categoriesOrderData = snapshot.val();
				setCategoriesOrder(categoriesOrderData);
			});
	};

	useEffect(() => {
		window.addEventListener("beforeunload", saveScrollPosition);
		getCategoriesOrderData();

		return () => {
			saveScrollPosition(); // Сохраняем позицию перед размонтированием
			window.removeEventListener("beforeunload", saveScrollPosition);
		};
	}, []);

	useEffect(() => {
		if (categoriesOrder) getCategoriesList();
	}, [props.categories, categoriesOrder]);

	// Восстанавливаем позицию скролла при обновлении списка категорий
	useEffect(() => {
		if (categoriesList && categoriesOrder) {
			restoreScrollPosition();
		}
	}, [categoriesList, categoriesOrder]);

	return categoriesList ? (
		<div className={classes.categoyContainer}>
			<ListCategory
				categories={categoriesList}
				deliveryTerminals={props.deliveryTerminals}
				handleCheckBox={props.handleCheckBox}
				defaultLanguage={props.defaultLanguage}
				hiddenCategories={props.hiddenCategories}
				defaultHiddenCategories={props.defaultHiddenCategories}
				hiddenCategory={props.hiddenCategory}
				languages={props.languages}
				language={props.defaultLanguage}
				activeCategories={props.activeCategories}
				deleteCategory={props.deleteCategory}
				editCategory={props.editCategory}
			/>
			<Link to={`${path}/addCategory`} className={classes.openModal}>
				<AddIcon className={classes.icon} />
			</Link>
		</div>
	) : (
		<Link to={`${path}/addCategory`} className={classes.openModal}>
			<AddIcon className={classes.icon} />
		</Link>
	);
};
const useStyles = makeStyles({
	container: {
		width: "100%",
		height: "auto",
		display: "flex",
		flexWrap: "wrap",
		padding: "20px 0px",
	},
	icon: {
		width: 35,
		height: 35,
		padding: 5,
		background: "#326dff",
		color: "white",
		borderRadius: "50%",
		position: "fixed",
		bottom: 15,
		right: 15,
		backgroundImage: "url(../../assets/img/plus.svg)",
		backgroundPosition: "center",
		backgroundSize: 20,
		backgroundRepeat: "no-repeat",
		opacity: 0.7,
		transition: "all 0.5s",
		cursor: "pointer",
		"&:hover": {
			opacity: 1,
		},
	},
});

export default withRouter(Categories);
