import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../../../firebase";

import {
	addShops,
	updateAdminSettings,
	updateLanguages,
	updateDefaultLanguage,
	updateCategoriesDispatch,
	updateProductsDispatch,
	updateKeyShopDispatch,
	updateKeyBrandDispatch,
	addAdminPermission,
	updateCategoriesOrder,
	updateCategoriesOrderDispatch,
} from "../../../reducers/shops";

import DefaultScreen from "./defaultScreen";

const DefaultScreenContainer = (props) => {
	const dispatch = useDispatch();
	const city = useSelector((state) => state.shops.shops);
	const keyShop = useSelector((state) => state.shops.keyShop);
	const keyBrand = useSelector((state) => state.shops.keyBrand);
	const categories = useSelector((state) => state.shops.categories);
	const products = useSelector((state) => state.shops.products);

	const [open, setOpen] = useState(false);
	const [isDirtyWorkingHours, setIsDirtyWorkingHours] = useState(false);
	const [openSettings, setOpenSettings] = useState(false);
	const [listCity, setListCity] = useState([]);
	const [defaultHiddenCategories, setDefaultHiddenCategories] = useState(null);
	const [defaultHiddenProducts, setDefaultHiddenProducts] = useState(null);
	const [defaultHiddenModifierGroups, setDefaultHiddenModifierGroups] =
		useState(null);
	const [
		defaultHiddenProductModification,
		setDefaultHiddenProductModification,
	] = useState(null);
	const [defaultCity, setDefaultCity] = useState(null);
	const [loadCity, setLoadCity] = useState(true);
	const [openCategory, setOpenCategory] = useState(null);
	const [openProducts, setOpenProducts] = useState(null);
	const [category, setCategory] = useState(null);
	const [adminPermission, setAdminPermission] = useState(null);
	const [adminSettings, setAdminSettings] = useState(null);
	const [mainSettings, setMainSettings] = useState(null);
	const [categoriesMenu, setCategoriesMenu] = useState(false);
	const [categoriesPromocode, setCategoriesPromocode] = useState(false);
	const [productsMenu, setProductsMenu] = useState(false);
	const [productsPromocode, setProductsPromocode] = useState(false);
	const [modifierGroupsMenu, setModifierGroupsMenu] = useState(false);
	const [productModificationMenu, setProductModificationMenu] = useState(false);
	const [preloader, setPreloader] = useState(false);

	const isDebug = props.isDebug;

	props.firebase.bd
		.ref(`${isDebug ? "debug" : ""}/adminPermission`)
		.once("value", (snapshot) => {
			const admin = snapshot.val();
			localStorage.setItem("adminPermission", JSON.stringify(admin));
		});

	// let brands = []

	useEffect(() => {
		// getCities();
		getDefaultCity();
		getAdminPermission();
		getAdminSettings();
		getDefaultHiddenCategories();
		getDefaultHiddenProducts();
		getDefaulHiddenModifierGroups();
		getDefaultHiddenProductModification();
		// getCategories();
		getProductsState();
		setTimeout(() => {
			getCities();
		}, 1000);
	}, [isDebug]);

	const getProductsState = () => {
		let isProducts = props.location.pathname.match("/page/menu/products");

		if (isProducts) {
			getDefaultLanguage();
			getLanguages();
			//   getAdminPermission();
			getCategories();
			getProducts();
		}
	};

	const getProducts = () => {
		const brand = JSON.parse(localStorage.getItem("currentBrand"));

		props.firebase.bd
			.ref(
				`${isDebug ? "debug" : ""}/EAppCRM/${brand.id}/nomenclature/products`
			)
			.once("value", (snapshot) => {
				const products = snapshot.val();
				if (products) updateProductsDispatch(dispatch, products);
			});
	};

	const getCategories = () => {
		const brand = JSON.parse(localStorage.getItem("currentBrand"));

		props.firebase.bd
			.ref(
				`${isDebug ? "debug" : ""}/EAppCRM/${brand.id}/nomenclature/categories`
			)
			.once("value", (snapshot) => {
				const categoriesData = snapshot.val();

				const categories = categoriesData
					? categoriesData.map((item) => ({
							...item,
							id: item.categoryId,
					  }))
					: [];

				updateCategoriesDispatch(dispatch, categories);
			});
	};

	const getCities = () => {
		setLoadCity(true);

		const admin = JSON.parse(localStorage.getItem("adminPermission"));
		const chosenBrand = JSON.parse(localStorage.getItem("chosenBrand"));
		const chosenCity = JSON.parse(localStorage.getItem("chosenCity"));
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		props.firebase.bd
			.ref(`${isDebug ? "debug" : ""}/settings`)
			.once("value", (snapshot) => {
				let items = snapshot.val();

				let listCity = [];

				let city = admin && admin.newAdmin === true ? items.city : items.City;
				for (let item in city) {
					console.log(city.item);
					listCity.push({
						...city[item],
						key: item,
						// cardPaymentSystem: city[item].brands[chosenBrand].cardPaymentSystem,
					});
				}

				// обновление магазина для каждого бренда прописано в файле навигации

				let data =
					admin && admin.newAdmin === true
						? {
								shops: listCity,
								appSettings: items.city[0]?.brands[0].appSettings,
								category: items.city[0]?.brands[0].category,
								mainSettings: items.mainSettings,
						  }
						: {
								shops: listCity,
								appSettings: items.appSettings,
								category: items.Category,
						  };
				// console.log('data to reducer', data);
				addShops(dispatch, data);
				setLoadCity(false);
				setListCity(listCity);
				setMainSettings(items.mainSettings);
			});
	};

	const getDefaultCity = () => {
		let cityId = window.location.pathname.split("/");
		cityId = cityId[cityId.length - 1];

		setLoadCity(true);
		props.firebase.bd
			.ref(`${isDebug ? "debug" : ""}/settings/City/${cityId}`)
			.on("value", (snapshot) => {
				let defaultCity = snapshot.val();

				// console.log('defaultCity', defaultCity);

				setDefaultCity(defaultCity);
				setLoadCity(false);
			});
	};

	const getAdminPermission = () => {
		props.firebase.bd
			.ref(`${isDebug ? "debug" : ""}/adminPermission`)
			.once("value", (snapshot) => {
				let adminPermission = snapshot.val();

				setAdminPermission(adminPermission);
				addAdminPermission(dispatch, adminPermission);
			});
	};

	const getAdminSettings = () => {
		props.firebase.bd
			.ref(`${isDebug ? "debug" : ""}/adminSettings`)
			.once("value", (snapshot) => {
				let val = snapshot.val();

				if (!adminSettings) {
					updateAdminSettings(dispatch, val);
					setAdminSettings(val);
				}
			});
	};

	const getDefaultHiddenCategories = () => {
		const currentCity = JSON.parse(localStorage.getItem("chosenCity"));
		const currentBrand = JSON.parse(localStorage.getItem("chosenBrand"));
		setLoadCity(true);
		props.firebase.bd
			.ref(
				`${
					isDebug ? "debug" : ""
				}/settings/city/${currentCity}/brands/${currentBrand}/category/hiddenCategories`
			)
			.once("value", (snapshot) => {
				let val = snapshot.val();
				setDefaultHiddenCategories(val);
				setLoadCity(false);
			});
	};

	const getDefaultHiddenProducts = () => {
		const currentCity = JSON.parse(localStorage.getItem("chosenCity"));
		const currentBrand = JSON.parse(localStorage.getItem("chosenBrand"));
		setLoadCity(true);
		props.firebase.bd
			.ref(
				`${
					isDebug ? "debug" : ""
				}/settings/city/${currentCity}/brands/${currentBrand}/category/hiddenProducts`
			)
			.once("value", (snapshot) => {
				let val = snapshot.val();

				setDefaultHiddenProducts(val);
				setLoadCity(false);
			});
	};

	const getDefaulHiddenModifierGroups = () => {
		const currentCity = JSON.parse(localStorage.getItem("chosenCity"));
		const currentBrand = JSON.parse(localStorage.getItem("chosenBrand"));
		setLoadCity(true);
		props.firebase.bd
			.ref(
				`${
					isDebug ? "debug" : ""
				}/settings/city/${currentCity}/brands/${currentBrand}/category/hiddenModifierGroups`
			)
			.once("value", (snapshot) => {
				let val = snapshot.val();

				setDefaultHiddenModifierGroups(val);
				setLoadCity(false);
			});
	};

	const getDefaultHiddenProductModification = () => {
		const currentCity = JSON.parse(localStorage.getItem("chosenCity"));
		const currentBrand = JSON.parse(localStorage.getItem("chosenBrand"));
		setLoadCity(true);
		props.firebase.bd
			.ref(
				`${
					isDebug ? "debug" : ""
				}/settings/city/${currentCity}/brands/${currentBrand}/category/hiddenProductModification`
			)
			.once("value", (snapshot) => {
				let val = snapshot.val();

				setDefaultHiddenProductModification(val);
				setLoadCity(false);
			});
	};

	const getDefaultLanguage = () => {
		props.firebase.bd
			.ref(`${isDebug ? "debug" : ""}/adminPermission/defaultLanguage`)
			.once("value", (snapshot) => {
				let defaultLanguage = snapshot.val();

				if (defaultLanguage) updateDefaultLanguage(dispatch, defaultLanguage);
			});
	};

	const getLanguages = () => {
		// props.firebase.bd.ref('languages').once("value", (snapshot) => {
		props.firebase.bd
			.ref(`${isDebug ? "debug" : ""}/adminPermission/languages`)
			.once("value", (snapshot) => {
				let languages = snapshot.val();

				if (languages) updateLanguages(dispatch, languages);
			});
	};

	const handleDrawerOpen = () => setOpen(true);
	const handleDrawerClose = () => setOpen(false);
	const handleCategoriesMenu = (value) => setCategoriesMenu(value);
	const handleProductsMenu = (value) => setProductsMenu(value);
	const handleModifierGroupsMenu = (value) => setModifierGroupsMenu(value);
	const handleProductModificationMenu = (value) =>
		setProductModificationMenu(value);
	const handleCategoriesPromocode = (value) => setCategoriesPromocode(value);
	const handleProductsPromocode = (value) => setProductsPromocode(value);
	const handleIsDirtyWorkingHours = (value) => setIsDirtyWorkingHours(value);

	// const openSettings = () => {
	//     if(!openSettings && listCity.length === 0) getCities();

	//     setOpenSettings(openSettings ? false : true);
	//     setCity(city);
	// }

	const chooseCity = (key) => {
		updateKeyShopDispatch(dispatch, keyShop === key ? null : key);
	};

	const chooseBrand = (key) => {
		updateKeyBrandDispatch(dispatch, keyBrand === key ? null : key);
	};

	const handleOpen = (category) => {
		setOpenCategory(category === openCategory ? null : category);
	};

	const handleProductOpen = (category) => {
		if (
			openProducts !== category &&
			products.length <= 0 &&
			categories.length <= 0
		) {
			setPreloader(true);
			getCategories();
			getProducts();
			getLanguages();
			getDefaultLanguage();
			setTimeout(() => setPreloader(false), 200);
		}

		setOpenProducts(category === openProducts ? null : category);
	};

	return (
		<DefaultScreen
			open={open}
			preloader={preloader}
			handleDrawerClose={handleDrawerClose}
			openSettings={openSettings}
			chooseCity={chooseCity}
			chooseBrand={chooseBrand}
			keyShop={keyShop}
			keyBrand={keyBrand}
			loadCity={loadCity}
			city={city}
			// brands={brands}
			defaultCity={defaultCity}
			defaultHiddenCategories={defaultHiddenCategories}
			defaultHiddenProducts={defaultHiddenProducts}
			defaultHiddenModifierGroups={defaultHiddenModifierGroups}
			defaultHiddenProductModification={defaultHiddenProductModification}
			getDefaultHiddenCategories={getDefaultHiddenCategories}
			getDefaultHiddenProducts={getDefaultHiddenProducts}
			getDefaulHiddenModifierGroups={getDefaulHiddenModifierGroups}
			getDefaultHiddenProductModification={getDefaultHiddenProductModification}
			openCategory={openCategory}
			openProducts={openProducts}
			handleOpen={handleOpen}
			handleProductOpen={handleProductOpen}
			adminPermission={adminPermission}
			adminSettings={adminSettings}
			mainSettings={mainSettings}
			categoriesMenu={categoriesMenu}
			productsMenu={productsMenu}
			categoriesPromocode={categoriesPromocode}
			productsPromocode={productsPromocode}
			isDirtyWorkingHours={isDirtyWorkingHours}
			handleIsDirtyWorkingHours={handleIsDirtyWorkingHours}
			handleDrawerOpen={handleDrawerOpen}
			handleCategoriesMenu={handleCategoriesMenu}
			handleProductsMenu={handleProductsMenu}
			handleModifierGroupsMenu={handleModifierGroupsMenu}
			handleProductModificationMenu={handleProductModificationMenu}
			getDefaultCity={getDefaultCity}
			handleCategoriesPromocode={handleCategoriesPromocode}
			handleProductsPromocode={handleProductsPromocode}
		/>
	);
};

export default withFirebase(withRouter(DefaultScreenContainer));
